/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCameraLinkInput = {
  cameras?: Array< CameraInput | null > | null,
  createdBy: string,
  siteCode: string,
  stepName: Step,
};

export type CameraInput = {
  systemIdentifier: string,
};

export enum Step {
  Induct = "Induct",
  Stow = "Stow",
  Pick = "Pick",
  Staging = "Staging",
  Dispatch = "Dispatch",
}


export type CameraLink = {
  __typename: "CameraLink",
  cameras?:  Array<CameraId | null > | null,
  created: string,
  createdBy: string,
  siteCode: string,
  stepName: Step,
  updated: string,
  updatedBy: string,
};

export type CameraId = {
  __typename: "CameraId",
  systemIdentifier: string,
};

export type UserActionInput = {
  actionId: string,
  actionName: string,
  parameters?: string | null,
  timestamp: string,
  ttl: number,
  username: string,
};

export type UserAction = {
  __typename: "UserAction",
  actionId: string,
  actionName: string,
  parameters?: string | null,
  timestamp: string,
  ttl: number,
  username: string,
};

export type DeleteCameraLinkInput = {
  partitionKey: string,
};

export type UpdateCameraLinkInput = {
  cameras?: Array< CameraInput | null > | null,
  siteCode: string,
  stepName: Step,
  updatedBy: string,
};

export type ApiResponse = {
  __typename: "ApiResponse",
  statusCode: string,
  body: string,
};

export type AthenaResponse = {
  __typename: "AthenaResponse",
  inductTime: string,
  stowTime?: string | null,
  pickTime?: string | null,
  stageTime?: string | null,
  dispatchTime?: string | null,
  dsTimeZone?: string | null,
};

export type Audit = {
  __typename: "Audit",
  eventId: string,
  tableName: string,
  actionTstampTx: string,
  action: string,
  rowData?: string | null,
  rowDataJson?: string | null,
  changedFields?: string | null,
  changedFieldsJson?: string | null,
};

export enum VMS {
  Milestone = "Milestone",
  Bosch = "Bosch",
}


export type Camera = {
  __typename: "Camera",
  name: string,
  description?: string | null,
  systemIdentifier: string,
};

export type Permission = {
  __typename: "Permission",
  HASPERMISSION: string,
};

export type Site = {
  __typename: "Site",
  SegmentName: string,
  SegmentLocation: string,
  SegmentSource: string,
  SiteCode: string,
};

export type PilotSite = {
  __typename: "PilotSite",
  siteCode: string,
  vms: VMS,
};

export type SIGInfraSite = {
  __typename: "SIGInfraSite",
  allSites?: boolean | null,
  sites?: Array< string | null > | null,
};

export type CreateCameraLinkMutationVariables = {
  input: CreateCameraLinkInput,
};

export type CreateCameraLinkMutation = {
  createCameraLink?:  {
    __typename: "CameraLink",
    cameras?:  Array< {
      __typename: "CameraId",
      systemIdentifier: string,
    } | null > | null,
    created: string,
    createdBy: string,
    siteCode: string,
    stepName: Step,
    updated: string,
    updatedBy: string,
  } | null,
};

export type CreateUserActionMutationVariables = {
  input: UserActionInput,
};

export type CreateUserActionMutation = {
  createUserAction?:  {
    __typename: "UserAction",
    actionId: string,
    actionName: string,
    parameters?: string | null,
    timestamp: string,
    ttl: number,
    username: string,
  } | null,
};

export type DeleteCameraLinkMutationVariables = {
  input: DeleteCameraLinkInput,
};

export type DeleteCameraLinkMutation = {
  deleteCameraLink?:  {
    __typename: "CameraLink",
    cameras?:  Array< {
      __typename: "CameraId",
      systemIdentifier: string,
    } | null > | null,
    created: string,
    createdBy: string,
    siteCode: string,
    stepName: Step,
    updated: string,
    updatedBy: string,
  } | null,
};

export type UpdateCameraLinkMutationVariables = {
  input: UpdateCameraLinkInput,
};

export type UpdateCameraLinkMutation = {
  updateCameraLink?:  {
    __typename: "CameraLink",
    cameras?:  Array< {
      __typename: "CameraId",
      systemIdentifier: string,
    } | null > | null,
    created: string,
    createdBy: string,
    siteCode: string,
    stepName: Step,
    updated: string,
    updatedBy: string,
  } | null,
};

export type GetMilestoneVideoUrlQueryVariables = {
  siteCode: string,
  cameraId: string,
  startTime: string,
  endTime: string,
};

export type GetMilestoneVideoUrlQuery = {
  getMilestoneVideoUrl?:  Array< {
    __typename: "ApiResponse",
    statusCode: string,
    body: string,
  } | null > | null,
};

export type GetBoschVideoUrlQueryVariables = {
  siteCode: string,
  cameraId: string,
  startTime: string,
  endTime: string,
};

export type GetBoschVideoUrlQuery = {
  getBoschVideoUrl?:  Array< {
    __typename: "ApiResponse",
    statusCode: string,
    body: string,
  } | null > | null,
};

export type GetImageCaptureQueryVariables = {
  siteCode: string,
  cameraId: string,
};

export type GetImageCaptureQuery = {
  getImageCapture?:  Array< {
    __typename: "ApiResponse",
    statusCode: string,
    body: string,
  } | null > | null,
};

export type GetParcelTrackingDataQueryVariables = {
  siteCode: string,
  trackingID: string,
  date: string,
};

export type GetParcelTrackingDataQuery = {
  getParcelTrackingData?:  Array< {
    __typename: "AthenaResponse",
    inductTime: string,
    stowTime?: string | null,
    pickTime?: string | null,
    stageTime?: string | null,
    dispatchTime?: string | null,
    dsTimeZone?: string | null,
  } | null > | null,
};

export type ListAuditQueryVariables = {
  startDate: string,
  endDate: string,
  limit: number,
  offset: number,
};

export type ListAuditQuery = {
  listAudit?:  Array< {
    __typename: "Audit",
    eventId: string,
    tableName: string,
    actionTstampTx: string,
    action: string,
    rowData?: string | null,
    rowDataJson?: string | null,
    changedFields?: string | null,
    changedFieldsJson?: string | null,
  } | null > | null,
};

export type ListSiteCamerasQueryVariables = {
  siteCode: string,
  vms: VMS,
};

export type ListSiteCamerasQuery = {
  listSiteCameras?:  Array< {
    __typename: "Camera",
    name: string,
    description?: string | null,
    systemIdentifier: string,
  } | null > | null,
};

export type ListCachedSiteCameraListQueryVariables = {
  siteCode: string,
};

export type ListCachedSiteCameraListQuery = {
  listCachedSiteCameraList?:  Array< {
    __typename: "Camera",
    name: string,
    description?: string | null,
    systemIdentifier: string,
  } | null > | null,
};

export type ListCameraLinksForSiteQueryVariables = {
  siteCode: string,
};

export type ListCameraLinksForSiteQuery = {
  listCameraLinksForSite?:  Array< {
    __typename: "CameraLink",
    cameras?:  Array< {
      __typename: "CameraId",
      systemIdentifier: string,
    } | null > | null,
    created: string,
    createdBy: string,
    siteCode: string,
    stepName: Step,
    updated: string,
    updatedBy: string,
  } | null > | null,
};

export type ListUserPermissionQueryVariables = {
  path: string,
};

export type ListUserPermissionQuery = {
  listUserPermission?:  Array< {
    __typename: "Permission",
    HASPERMISSION: string,
  } | null > | null,
};

export type ListUserSitesQueryVariables = {
  path: string,
};

export type ListUserSitesQuery = {
  listUserSites?:  Array< {
    __typename: "Site",
    SegmentName: string,
    SegmentLocation: string,
    SegmentSource: string,
    SiteCode: string,
  } | null > | null,
};

export type ListPilotUserSitesQueryVariables = {
};

export type ListPilotUserSitesQuery = {
  listPilotUserSites?:  Array< {
    __typename: "PilotSite",
    siteCode: string,
    vms: VMS,
  } | null > | null,
};

export type ListSIGInfraUserSitesQueryVariables = {
  username: string,
};

export type ListSIGInfraUserSitesQuery = {
  listSIGInfraUserSites?:  {
    __typename: "SIGInfraSite",
    allSites?: boolean | null,
    sites?: Array< string | null > | null,
  } | null,
};
