import {
  Box,
  Button,
  ButtonProps,
  Flashbar,
  FormField,
  Grid,
  Modal,
  Select,
  SelectProps,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  PilotSite,
  Step,
} from 'src/API';
import {
  ParcelPlaybackBaseStateInterface,
  ParcelPlaybackSearchState,
} from 'src/stores/app';
import { State, useHookstate } from '@hookstate/core';
import { StepOptions, UserActionNames } from 'src/constants';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { useBundle } from '@amzn/react-arb-tools';
import { useEffect, useRef, useState } from 'react';
import { addDays } from 'date-fns';

export interface ISelectSiteProps {
  parcelPlaybackState: State<ParcelPlaybackBaseStateInterface>;
  refreshUserSitesCallback: Function;
  siteSelectedCallback: Function;
  userSites: PilotSite[];
  userSitesLoading: boolean;
}

export default function SelectSite(props: ISelectSiteProps) {

  const parcelPlaybackSearchState = useHookstate(ParcelPlaybackSearchState);

  const [selectedSiteCode, setSelectedSiteCode] = useState<string | null>(props.parcelPlaybackState.value.selectedSite?.siteCode ?? null);
  const [showSelectSiteMessage, setShowSelectSiteMessage] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.common.SelectSite');

  const selectSiteButtonRef = useRef<ButtonProps.Ref>(null);
  const siteInputRef = useRef<SelectProps.Ref>(null);

  const selectSiteHandler = async () => {
    createUserAction({
      actionName: UserActionNames.SelectSite,
      username: props.parcelPlaybackState.username.value,
      parameters: JSON.stringify({
        selectedSite: selectedSiteCode,
      }),
    });


    const selectedSite = {
      siteCode: selectedSiteCode!,
      vms: props.userSites.find(s => s.siteCode === selectedSiteCode)?.vms!,
    };

    props.parcelPlaybackState.selectedSite.set(selectedSite);
    localStorage.setItem('lastSelectedSite', JSON.stringify(selectedSite));
    parcelPlaybackSearchState.set({
      activeTabId: Step.Induct,
      cameraLoadProgress: {},
      cardItems: [],
      dispatchDisableTab: true,
      dispatchLabel: `${Step.Dispatch} - No Timestamp`,
      inductDisableTab: true,
      inductLabel: `${Step.Induct} - No Timestamp`,
      invalidSearchDate: false,
      isModalLoading: false,
      isNextButtonDisabled: false,
      isPrevButtonDisabled: false,
      loadingTrackingData: false,
      modalCameraId: '',
      modalCameraName: '',
      modalUrl: '',
      modalVideoStartTime: '',
      modalVideoEndTime: '',
      modalVideoVisible: false,
      noParcelDataFound: false,
      pickDisableTab: true,
      pickLabel: `${Step.Pick} - No Timestamp`,
      postRoll: '15 seconds',
      postRollOptions: undefined,
      postUrl: '',
      preRoll: '15 seconds',
      preRollOptions: undefined,
      preUrl: '',
      searchStartDate: (addDays(new Date(), -7).toISOString().split('T')[0]),
      selectedSteps: StepOptions.map(s => ({
        label: s.label,
        value: s.value,
      })),
      stageDisableTab: true,
      stagingLabel: 'Staging - No Timestamp',
      stepLoadingStatus: {},
      stowDisableTab: true,
      stowLabel: `${Step.Stow} - No Timestamp`,
      trackingId: undefined,
    });
    props.siteSelectedCallback();
  };

  const siteFieldOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    setSelectedSiteCode(detail.selectedOption.label ?? null);
    setShowSelectSiteMessage(false);
  };

  const stage = 'gamma';

  const FlashMessageSelectSite = () => (
    <Flashbar
      items={[
        {
          content: bundle.getMessage('please-select-a-site.'),
          dismissible: true,
          onDismiss: () => setShowSelectSiteMessage(false),
          type: 'warning',
        },
      ]}
    />
  );

  useEffect(() => {
    if (siteInputRef.current) siteInputRef.current?.focus();
  }, [siteInputRef.current]);

  if (isBundleLoading) return <Spinner />;

  return (
    <Modal
      footer={
        <Box float='right'>
          <Button
            disabled={selectedSiteCode === null}
            loading={false}
            onClick={selectSiteHandler}
            ref={selectSiteButtonRef}
            variant='primary'
          >
            {bundle.getMessage('select')}
          </Button>
        </Box>
      }
      header={
        <>
          {`${bundle.getMessage('site')} ${(stage.toLowerCase() !== 'prod' ? ' - ' + stage.toUpperCase() : '')}`}
        </>
      }
      size='small'
      visible
      onDismiss={() => {
        if (selectedSiteCode !== null) props.siteSelectedCallback();
        if (selectedSiteCode === null) setShowSelectSiteMessage(true);
      }}
    >
      {showSelectSiteMessage && <FlashMessageSelectSite />}
      <Grid gridDefinition={[{colspan: 8}, {colspan: 2}]}>
        <FormField label={bundle.getMessage('site')}>
          <Select
            autoFocus
            expandToViewport
            filteringType='auto'
            loadingText='Loading Your Sites'
            onChange={({ detail }) => {
              selectSiteButtonRef.current?.focus();
              siteFieldOnChangeHandler(detail);
            }}
            options={props.userSites
              .map((el) => {
                return {
                  label: el.siteCode,
                  value: el.siteCode,
                };
              })
              .sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
              })}
            placeholder={bundle.getMessage('select-a-site')}
            ref={siteInputRef}
            selectedAriaLabel='Selected'
            selectedOption={
              selectedSiteCode
              ?
                {
                  label: selectedSiteCode,
                  value: selectedSiteCode,
                }
              :
                null}
            statusType={props.userSitesLoading ? 'loading' : 'finished'}
            virtualScroll={props.userSites.length > 500}
          />
        </FormField>
        <FormField label='&#8203;'>
          <Button
            ariaLabel='refresh sites'
            iconName='refresh'
            loading={props.userSitesLoading}
            onClick={() => props.refreshUserSitesCallback()}
            variant='normal'
          />
        </FormField>
      </Grid>
    </Modal>
  );
}
